@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.collapseBox {
    width: 100%;

    +.collapseBox {
        margin-top: 12px;
    }
}

.collapseBoxMd {
    width: 100%;

    +.collapseBoxMd {
        margin-top: 24px;
        @include mobile {
            margin-top: 12px;
        }
    }
}

.collapseHeader {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid $green-deep;
    border-radius: 4px;
    cursor: pointer;
}

.title {
    font-size: 16px;
    line-height: 26px;
    padding: 5px 10px 5px 0;
    color: $green-deep;
}

.titleMd {
    font-size: 24px;
    line-height: 140%;
    color: $green-deep;
    @include mobile {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
    }
}

.arrowWrapper {
    flex: 0 0 36px;
    margin-left: 10px;

    @include mobile {
        flex: 0 0 24px;
    }
}

.arrow {
    width: 36px;
    height: 36px;

    @include mobile {
        width: 24px;
        height: 24px;
    }

    transition: transform 0.5s ease;
}

.arrowReverse {
    transform: rotate(180deg);
}

.open {
    background-color: $green-mist;
}

.content {
    @include markdownContent;
}

.contentMd {
    @include markdownContent;
    padding-bottom: 24px;
}