@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.desktopOnly {
  @include desktopOnly;
}

.mobileOnly {
  @include mobileOnly;
}

.introSection {
  margin-top: 0px;
}

.introSectionMobileTop {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 40px 32px;
}

.introSectionMobileBottom {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 32px;
}

.intro {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.introContent {
  flex: 1;
}

.dummySpacing {
  width: 80px;

  @include tablet {
    width: 60px;
  }

  @include mobile {
    display: none;
  }
}

.introImageWrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
  &:before{
    content: '';
    display: block;
    padding-bottom: 61.6%;
  }

  @include mobile {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
}

.introTitle {
  margin: 0;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 45px;
  font-weight: 500;

  @include mobile {
    font-size: 24px;
    line-height: 150%;
  }
}

.introDescription {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin: 0px;
  margin-bottom: 90px;
  color: $color-neutral-200;

  @include tablet {
    margin-bottom: 40px;
  }
}

.features {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0px;
  }
}