@import "/styles/variables.scss";
@import "/styles/mixin.scss";

$card-amount: 3;
$card-blanks: $card-amount - 1;
$blanks-width: 180px;

.timelineSection {
  background-color: $green-mist;
}

.card {
  min-width: 250px;
  width: calc((100% - $blanks-width * $card-blanks) / $card-amount);
  @include tablet {
    width: 100%;
  }
}

.stepTitle {
  font-size: 32px;
  line-height: 140%;
  color: $green-deep;
  font-weight: 300;
  font-style: italic;
  margin: 0 0 12px 0;
}

.cardBody {
  display: flex;
  background-color: $color-neutral-900;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 24px;
  text-align: center;
}

.cardIconWrapper {
  margin-bottom: 16px;
}

.cardTitleArea {
  margin: 0 0 16px 0;
}

.cardTitle {
  font-size: 24px;
  line-height: 1.4;
  margin: 0;
}

.cardDescription {
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
}

.cardBlank {
  padding-top: 44.8px;
  width: 60px;
  @include tablet {
    padding: 0;
    margin: 24px 0;
  }
}

.cardBlankArrow {
  @include tablet {
    transform: rotate(90deg);
  }
}

// section

.content {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include tablet {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  } 
}

.button {
  margin-top: 40px;
}