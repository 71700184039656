@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.nextSection {
    padding: 60px 0;
    margin: 0 auto;
    cursor: pointer;

    @include mobile {
        padding: 40px 32px;
    }
}

.content {
    @include contentMaxLimit(0px, 0px, 0px, 0px, $padding-width-m);
}


.next {
    font-size: 24px;
    display: flex;
    align-items: center;
    font-weight: 500;

    @include mobile {
        font-size: 18px;
        line-height: 150%;
    }
}

.title {
    font-size: 32px;
    padding-top: 16px;
    line-height: 150%;
    font-weight: 500;

    @include mobile {
        font-size: 24px;
    }
}

.theme-white {
    background-color: $color-neutral-900;
    color: $green-deep;
    .arrow {
        &.white {
            display: none;
        }
    
        &.green {
            display: block;
        }
    }

    &:hover {
        background-color: $green-mist;
    }
}

.theme-green {
    background-color: $green-mist;
    color: $green-deep;

    .arrow {
        margin-left: 8px;
    
        &.white {
            display: none;
        }
    
        &.green {
            display: block;
        }
    }

    &:hover {
        .title {
            color: $color-neutral-900;
        }
        .next {
            color: $color-neutral-900;
        }

        .arrow {
            filter: brightness(100);

            &.white {
                display: block;
            }
        
            &.green {
                display: none;
            }
        }

        background-color: $green-tree;
    }
}