@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.markdown {
    & h1, h2, h3, h4, h5, h6 {
        margin-bottom: 24px;
        font-weight: 500;
        color: $green-tree
    }

    & p:not(:last-child) {
        margin-bottom: 80px;

        @include mobile {
            margin-bottom: 40px;
        }
    }

    a {
        color: $green-tree;
        font-weight: 500;
        text-decoration: underline;
    }
}