@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.section {
  padding-top: 100px;

  @include mobile {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.title {
  font-size: 32px;
  line-height: 45px;
  margin: 0 0 40px 0;

  @include mobile {
    font-size: 24px;
    margin-bottom: 24px;
    color: $green-deep;
  }
}

.button {
  margin-top: 40px;
}

.cta {
  overflow: hidden;
  position: relative;
  background-color: $green-tree;
  width: 100%;
  height: 160px;
  margin-top: 48px;
  padding: 0 92px 0 96px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  @include mobile {
    padding: 32px;
    height: auto;
  }
}

.ctaContent {
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
    font-size: 24px;
    line-height: 32px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.ctaBg {
  position: absolute;
  z-index: 0;
  left: 27%;
  top: 50%;
  transform: translateY(-50%);
  width: 66%;

  @include mobile {
    width: auto;
    transform: none;
    top: initial;
    left: 20px;
    bottom: -24px;
  }
}

.ctaBgText {
  font-size: $width-content-d * 0.1947; // bg width(0.66) / word count(6) * ratio(1.77)
  color: $green-mist;
  opacity: 0.1;
  font-weight: 500;

  @include laptop {
    font-size: $width-content-l * 0.1947; // bg width(0.66) / word count(6) * ratio(1.77)
  }

  @include large-tablet {
    font-size: $width-content-lt * 0.1947; // bg width(0.66) / word count(6) * ratio(1.77)
  }

  @include tablet {
    font-size: $width-content-t * 0.1947; // bg width(0.66) / word count(6) * ratio(1.77)
  }

  @include mobile {
    font-size: 128px;
    line-height: 180px;
  }
}

.ctaTitle {
  font-size: 24px;
  line-height: 140%;
  color: $green-mist;

  @include mobile {
    margin: 0 0 40px 0;
  }
}