@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.wrapper {
  position: relative;
  width: calc((100% - 3 * 30px) / 4);
  background-color: $green-mist;
  transition: 0.3s;
  cursor: pointer;

  .iconWrapper {
    width: 60px;
    height: 60px;
    transition: 0.3s
  }

  &:hover {
    .iconWrapper {
      transform: scale(1.16);
    }
  }

  // &:hover {
  //   background-color: $green-tree;
  //   color: $color-neutral-900;

  //   .iconWrapper {
  //     transition: 0.3s;
  //     filter: brightness(0) invert(1);
  //   }
  // }

  // &:active {
  //   background-color: $green-deep;
  // }

  @include imageWrapper(100%);

  @include tablet {
    width: calc((100% - 24px) / 2);

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 24px;
    }
  }

  @include mobile {
    margin: 0 0 24px 0;
    width: 100%;

    &:last-child {
      margin: 0;
    }
  }
}

.content {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 24px;
  line-height: 34px;
  margin: 16px 0;
}

.keypoints {
  @include smallDotList;
}