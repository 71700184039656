@import "/styles/variables.scss";
@import "/styles/mixin.scss";

.card {
  position: relative;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);

    .cta > span {
      margin-right: 12px;
    }
  }
}

.cardInfo {
  min-height: 224px;
  padding: 24px;
  background-color: white;
}

.cardImg {
  height: 220px;
  position: relative;
  width: 100%;
  @include imageWrapper(60.8%);

  .commaIconWrapper {
    position: absolute;
    left: 15px;
    bottom: -14px;
  }

  & img {
    object-position: center;
    object-fit: cover;
  }
}

.cardInfo {
  padding: 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.sharingWords {
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.footer {
  margin-top: auto;
}

.sharer,
.sharerTitle {
  font-size: 12px;
  line-height: 1.6;
  margin: 0;
}

.sharer {
  font-weight: 500;
}

.sharerTitle {
  font-weight: 400;
}

.cta {
  margin-top: 40px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: $green-tree;
  letter-spacing: 0.12em;
  cursor: pointer;

  > span {
    transition: 0.3s;
    margin-right: 8px;
  }
}