@import "/styles/variables.scss";
@import "/styles/mixin.scss";

$padding-carouselItem-d: 15px;
$padding-carouselItem-l: 15px;
$padding-carouselItem-lt: 20px;
$padding-carouselItem-t: 10px;

.alumniContainer {
    background: linear-gradient(15deg, white 600px, $green-grass 0);

    @include mobile {
        background: none;
        margin-top: 20px;
    }
}

.SwiperSlide {
    padding: 0 4px;
}

.cardWrapper {
    padding-bottom: 72px;

    @include mobile {
        padding-bottom: 56px;
    }
}

.title {
    font-size: 32px;
    line-height: 140%;
    color: $color-neutral-900;
    margin-top: 0;
    margin-bottom: 24px;

    @include mobile {
        font-size: 24px;
        color: $color-neutral-200
    }
}

.button {
    display: none;

    @include mobile {
        display: inline-flex;
        margin-top: 40px;
    }
}